// HOME SWIPER
const homeSwiper = new Swiper('#home-swiper', {
    loop: true,
    parallax: true,
    speed: 1000,
    pagination: {
    el: '.swiper-pagination-home-swiper',
        type: 'progressbar',
    },
    navigation: {
        nextEl: '.swiper-button-next-home-swiper',
        prevEl: '.swiper-button-prev-home-swiper',
    },
    autoplay: {
        delay: 6000,
    }
});

// GALLERY SWPIER
const galleryThumbs = new Swiper('.gallery-thumbs', {
    spaceBetween: 10,
    slidesPerView: 6,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
        320: {
            slidesPerView: 1,
        },

        480: {
            slidesPerView: 2,
        },

        768: {
            slidesPerView: 3,
        }
    }
  });

const galleryTop = new Swiper('.gallery-top', {
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next-gallery-top',
      prevEl: '.swiper-button-prev-gallery-top',
    },
    thumbs: {
      swiper: galleryThumbs
    }
});