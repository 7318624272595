$(function(){
    AOS.init();
    let $start = $('#arrival'),
    $end = $('#departure');

        $start.datepicker({
            minDate: new Date(),
            onSelect: function (fd, date) {
                $end.data('datepicker')
                        .update('minDate', date);

                $end.focus();
            }
        })

        $end.datepicker({
            onSelect: function (fd, date) {
                $start.data('datepicker')
                        .update('maxDate', date)
            }
        })
        
    // scroll with stick menu
    $(window).scroll(function() {
        if ($(this).scrollTop() > 35) {
            $('.wrapper').addClass('is-active')
        } else {
            $('.wrapper').removeClass('is-active')
        }
    });

    // add tippy to icons
    tippy('.room-info-icon, .social-icon', {
        animation: 'scale',
    })

    // footer year
    const date = new Date();
    const year = date.getFullYear();
    $('#year').text(year);

    // hamburger
    $('.hamburger-button').click(function() {
        $(this).toggleClass('open')
        $('body').toggleClass('menuOpen');
        $('.main-nav').toggleClass('isOpen');
    })

    // Dropdown language
    $(".dropdown").click(function(){
        let self = $(this)
        let element = $('.dropdown-menu');

        self.toggleClass('is-active')
        
        element.toggleClass('d-none')
        element.css({
            'top': '20px',
            'left': self.offset().left - 20,
        })
    })

    $('#information svg').click( () => {
        $('#information').addClass('removeInformation');
        // localStorage.setItem('information', 'true')
    })

    // popup
    const popup = localStorage.getItem('popup');
    if(!popup) {
        $('#popup').addClass('active');
        $('.black-screen').addClass('active');
    }

    $('#popup a').on('click', function() {
        localStorage.setItem('popup', 'true')
        $('#popup').removeClass('active');
        $('.black-screen').removeClass('active');
    })

    $('#popup__x').on('click', function() {
        $('#popup').removeClass('active');
        $('.black-screen').removeClass('active');
    })
});